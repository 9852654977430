import React, { Component } from 'react'
import {IDividerAsProps, TooltipHost } from "@fluentui/react";
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt,faCommentDots, faCommentSlash, faLandmark, faDownload, faUser } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { withRouter } from "react-router";
//ToolTip
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//Badge
import Badge from 'react-bootstrap/Badge'
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset, getCaseDetail, createThread } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast, successToast, infoToast, dismissInfo } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
import moment from 'moment';
import { endPoints, apiPath, dateTimeFormat, headers } from '../../config/ApiConfig';
import { downloadDocument } from "../../services/RestAPIWrapper"
// dropzone file uploader
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css';

// Preview overwrite
const Preview = ({ meta }) => {
    const { name, percent, status } = meta
    return (
        <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica', width: "100%" }}>
            {name},{status !== "headers_received" ? status : ""}
            {(() => {
                switch (status) {
                    case "headers_received": return <ProgressBar striped variant="success" now={Math.round(percent)} label={`${Math.round(percent)}%`} />;
                    case "done": return <ProgressBar striped variant="success" now={Math.round(percent)} label={`${Math.round(percent)}%`} />;
                    case "error_upload": return <ProgressBar striped variant="danger" now={Math.round(percent)} label={`${Math.round(percent)}%`} />;
                    default: return <ProgressBar striped now={Math.round(percent)} label={`${Math.round(percent)}%`} />;
                }
            })()}
        </span>
    )
}
// submit button overwrite
const SubmitButton = ({ files }) => {
    const handleSubmit = (selectedFiles) => {
        selectedFiles.forEach(f => f.restart())
    }
    const disabledstatus =
        files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
        !files.some(f => ['headers_received', 'done'].includes(f.meta.status))
    return (
        <Button className="btn rounded pt-2 pb-2 pl-4 text-uppercase pr-4"
            style={{ backgroundColor: "#122e43", color: "#ffffff" }}
            onClick={() => { handleSubmit(files) }}
            disabled={disabledstatus}
        >
            Last opp
        </Button>
    )
}

class CaseDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            caseInformation: {},
            lawyer: [],
            documents: [],
            message: [],
            messageInput: "",
            count: 0,
            showViewAllMessageButton: false,
            ellipsText: 92,
            fpath: "root",
            itemsWithHeading: []
        }
    }

    componentWillMount() {
        this.props.reset();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication || !this.props.location.state) {
                this.props.history.push('/CaseList')
            } else {
                this.getDocuments(this.state.fpath);
            }
        }
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/CaseList')
        }

        if (window.innerWidth <= 500) {
            this.setState({
                ellipsText: 35
            })
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.casedetails).length > 0) {
            let itemsWithHeading = [];
            let sharePointUrl;
            const caseDetail = nextprops.casedetails;
            if (caseDetail.Success) {
                this.setState({
                    caseInformation: caseDetail.Result.LegalCase,
                    lawyer: caseDetail.Result.LegalCase.Lawyers,
                    documents: caseDetail.Result.Documents,
                    message: caseDetail.Result.AllThreads.Threads,
                    showViewAllMessageButton: caseDetail.Result.AllThreads.isMoreThreadAvailable,
                })
                    sharePointUrl = caseDetail.Result.sharePointUrl;
                    var rootPath = sharePointUrl.split("sharepoint.com")[1];
                    let folderPath = this.state.fpath == "root" ? rootPath : this.state.fpath;
                    let breadcrumbItems = folderPath.split("/");
                    let rootFolder = "Hovedmappe";
                    let prifixKey = breadcrumbItems.slice(0, 5).join("/");
                    if (breadcrumbItems.length > 5) {
                        breadcrumbItems = breadcrumbItems.slice(5, breadcrumbItems.length);
                        breadcrumbItems.splice(0, 0, rootFolder);
                    } else {
                        breadcrumbItems = [];
                        breadcrumbItems.push(rootFolder);
                    }
                    let keyValue = "";
                    breadcrumbItems.forEach((element) => {
                        if (element == rootFolder) {
                            keyValue += prifixKey;
                        } else {
                            keyValue += "/" + element;
                        }
                        itemsWithHeading.push({
                            text: element,
                            key: keyValue,
                            onClick: this.onBreadcrumbItemClicked,
                        });
                    })
                    this.setState({
                        itemsWithHeading: itemsWithHeading
                    })
                
            } else {
                errorToast(caseDetail.Message)
            }
        }

        if (Object.keys(nextprops.createthread).length > 0) {
            const createMessage = nextprops.createthread;
            if (createMessage.Success) {
                this.props.reset();
                const caseid = this.state.caseInformation.Id;
                let clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
                const caseDetailParam = `?caseId=${caseid}&clientId=${clientId}&folderPath=root`;
                this.props.getCaseDetail(caseDetailParam);
                this.setState({
                    messageInput: ""
                })

            } else {
                errorToast(createMessage.Message)
            }
        }

    }

    getDocuments = (folderpath) => {
        this.setState({
            fpath: folderpath
        });

        const folderPath = folderpath ? folderpath : "root";
        const caseid = this.props.location.state.caseId;
        const clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
        const documentDetailsParams = `?caseId=${caseid}&clientId=${clientId}&folderPath=${encodeURIComponent(folderPath)}`;
        this.props.getCaseDetail(documentDetailsParams);
    }
    onBreadcrumbItemClicked = (ev, item) => {
        if (item) {
            this.getDocuments(item.key);
        }
        else {
            this.getDocuments(this.state.fpath);
        }
    }
    _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
        const tooltipText = dividerProps.item ? dividerProps.item.text : "";
        return (
            <TooltipHost
                content={`Show ${tooltipText} contents`}
                calloutProps={{ gapSpace: 0 }}
            >
                <span aria-hidden="true" style={{ cursor: "pointer", padding: 5 }}>
                    /
                </span>
            </TooltipHost>
        );
    }
    handleDownload = (downloadURL, name) => {
        infoToast(this.props.t('caseDetails.downloadInfo'))
        downloadDocument(downloadURL, 'blob', response => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
                dismissInfo()
                setTimeout(() => {
                    successToast(this.props.t('caseDetails.downloadSuccess'))
                }, 500);
            } else {
                dismissInfo()
                errorToast(this.props.t('caseDetails.downloadError'))
            }
        }, err => {
            dismissInfo()
            errorToast(this.props.t('caseDetails.downloadError'))
            console.error(err);
        });
    }

    getUploadParams = ({ file, meta }) => {
        var publisherType = localStorage.getItem("dynamicsEntity");
        const userId = JSON.parse(localStorage.getItem("UserInfo")).userId;
        const name = JSON.parse(localStorage.getItem("UserInfo")).name;
        let renderPublisher = {
            Accounts: [],
            Contacts: []
        }
        let postData = {
            Id: "",
            Name: ""
        };
        if (publisherType == "account") {
            postData.Id = userId;
            postData.Name = name;
            renderPublisher.Accounts.push(postData);
        }
        if (publisherType == "contact") {
            postData.Id = userId;
            postData.Name = name;
            renderPublisher.Contacts.push(postData);
        }
        var publisherJsonString = JSON.stringify(renderPublisher);
        const body = new FormData()
        body.append('Document', file)
        body.append('caseId', this.state.caseInformation.Id)
        body.append('publisherInfoJson', publisherJsonString)
        return { url: `${apiPath}${endPoints.uploadDocument.endPoint}`, body, headers: headers.headers }
    }

    handleChangeStatus = ({ meta }, status, Files) => {
        if (status === 'ready') { meta.status = 'headers_received' }

        if (status === 'done') {
            this.setState({
                count: this.state.count + 1
            })
        }
        if (status === 'rejected_file_type') {
            errorToast(this.props.t('caseDetails.fileUploadReject'))
        }
        if (status === 'error_upload') {
            errorToast(`${meta.name} ${this.props.t('caseDetails.fileUploadError')}`)
            setTimeout(() => {
                Files.forEach(f => {
                    if (f.meta.status === "done") {
                        f.remove()
                    }
                })
            }, 500);
        }

        setTimeout(() => {
            if (this.state.count !== 0) {
                if ((this.state.count) === Files.length) {
                    successToast(this.props.t('caseDetails.fileUploadSuccess'))
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                    this.setState({
                        count: 0
                    })
                }
            }
        }, 100);

    }

    handleSubmitFileUploader = (files, allFiles) => {

    }

    handleThreadInput = (e) => {
        this.setState({
            messageInput: e.target.value
        })
    }

    handleCreateThread = () => {
        if (this.state.messageInput !== "") {
            const createThreadObj = {
                threadId: "00000000-0000-0000-0000-000000000000",
                caseId: this.state.caseInformation.Id,
                subject: this.state.messageInput,
                description: "",
                createdFrom: "ClientPortal"
            }
            this.props.createThread(createThreadObj)
        } else {
            errorToast(this.props.t('caseDetails.creatThreadErrorMessage'))
        }
    }

    render() {
        return (
            <div className="Dashboard">
                {this.props.fetching && !this.props.error && <Loading />}
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area-details">
                            <h1 className="ml-3 mb-3 case-title" style={{ float: "left" }}>{this.state.caseInformation.Title}</h1>
                            <div className="item first-item">
                                <p className="mb-3">{this.state.caseInformation.Description}</p>
                                <h3 className="mb-2"> {this.props.t('caseDetails.projectDetails')}
                                    <span className="unOpened-messages ml-3"><FontAwesomeIcon icon={faCommentDots} /> <span>{this.state.caseInformation.UnReadMessageCount}</span></span>
                                    <span className="unOpened-document"><FontAwesomeIcon icon={faFileAlt} /><span>{this.state.caseInformation.UnOpenDocumentCount}</span></span></h3>
                                <ul className="d-flex mb-4">
                                    <li className="case-point-list"><span className="d-block">{this.props.t('caseDetails.legalArea')}</span>
                                        {this.state.caseInformation.LegalArea}</li>
                                    <li className="case-point-list"><span className="d-block">{this.props.t('caseDetails.legalBranch')}</span>
                                        {this.state.caseInformation.LegalBranch}</li>
                                </ul>
                                {window.globalConfig.CustomerName !== "Kreftforeningen" &&
                                    <h3 className="mb-2">{this.props.t('caseDetails.lawyer')}</h3> &&
                                    this.state.lawyer.map((Lkey, i) => (
                                        <ul key={i} className="d-flex mb-0">
                                            <li className="case-point-list case-lawyer mb-2">
                                                <img src={Lkey.image} alt="" />
                                            </li>
                                            <li className="case-point-list">
                                                <span className="d-block">{this.props.t('caseDetails.lawyerName')}</span>
                                                {Lkey.name}
                                            </li>
                                            <li className="case-point-list">
                                                <span className="d-block">{this.props.t('caseDetails.lawyerMobile')}</span>
                                                {Lkey.phone}
                                            </li>
                                            <li className="case-point-list">
                                                <span className="d-block">{this.props.t('caseDetails.lawyerEmail')}</span>
                                                < a href={"mailto:" + Lkey.email}>{Lkey.email}</a>
                                            </li>
                                        </ul>
                                    ))
                                }
                            </div>
                            {this.state.caseInformation.AccessToDialouge &&
                                <div className="item">
                                    <h2 className="w-100 d-flex align-items-center justify-content-between mb-3">
                                        {this.props.t('caseDetails.message')}
                                        {this.state.showViewAllMessageButton ?
                                            <Link to={{
                                                pathname: "/ThreadDetails",
                                                state: {
                                                    caseId: this.state.caseInformation.Id,
                                                    caseTitle: this.state.caseInformation.Title,
                                                    caseDescription: this.state.caseInformation.Description,
                                                }
                                            }}
                                                className="smallbtn">{this.props.t('caseDetails.viewAllMessages')}</Link>
                                            : null
                                        }
                                    </h2>

                                    <div className="CreateThread mb-2">
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="Create a Thread"
                                                aria-label="Create a Thread"
                                                aria-describedby="basic-addon2"
                                                onChange={this.handleThreadInput}
                                                value={this.state.messageInput}
                                            />
                                            <InputGroup.Append>
                                                <Button className="primarybtn" onClick={this.handleCreateThread}>
                                                    {this.props.t('caseDetails.messageThread')}
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>

                                    {
                                        this.state.message.map((key, i) => (
                                            <ul key={i} className="MessageList">
                                                {
                                                    !key.status ?
                                                        <li className="disabled">
                                                            <p className="font-weight-bold color1">{key.subject}</p>
                                                            <p>
                                                                {((key.latestMessage.description).length > this.state.ellipsText) ?
                                                                    (((key.latestMessage.description).substring(0, this.state.ellipsText - 3)) + '...') :
                                                                    key.latestMessage.description}
                                                            </p>
                                                            <p>{moment(key.createdOn).format(dateTimeFormat.dateFormat)}  |
                                                                <Link to="#" className="colorred font-weight-bold">{this.props.t('caseDetails.closeMessage')}</Link></p>
                                                            <FontAwesomeIcon style={{ fontSize: "25px" }} className="messageicon color3" icon={faCommentSlash} />
                                                            <p className="color1">{key.description}</p>
                                                        </li>
                                                        :
                                                        <Link to={{
                                                            pathname: "/Chat",
                                                            state: {
                                                                caseId: this.state.caseInformation.Id,
                                                                caseTitle: this.state.caseInformation.Title,
                                                                caseDescription: this.state.caseInformation.Description,
                                                                messageId: key.id,
                                                                messageSubject: key.subject,
                                                                messageDate: moment(key.createdOn).format(dateTimeFormat.dateFormat)
                                                            }
                                                        }}>
                                                            <li className={key.latestMessage.HasRead ? "" : "unreadmessage"}>
                                                                <p className="font-weight-bold color1">{key.subject}
                                                                    {key.latestMessage.HasRead ? "" : <Badge className="ml-2 newbage">{this.props.t('caseDetails.new')}</Badge>}
                                                                </p>                                                               
                                                                <p>
                                                                    {((key.latestMessage.description).length > this.state.ellipsText) ?
                                                                        (((key.latestMessage.description).substring(0, this.state.ellipsText - 3)) + '...') :
                                                                        key.latestMessage.description}
                                                                </p>
                                                                <p>{moment(key.createdOn).format(dateTimeFormat.dateFormat)}  |
                                                                    <span className="font-weight-bold color1">{this.props.t('caseDetails.messageReply')}</span>
                                                                </p>
                                                                <FontAwesomeIcon className="messageicon color3" style={{ fontSize: "25px" }} icon={faCommentDots} /> <span className="spanbadge">{key.UnReadMessageCount}</span>
                                                                <p className="color1">{key.description}</p>
                                                            </li>
                                                        </Link>
                                                }
                                            </ul>
                                        ))
                                    }
                                </div>
                            }
                            {(this.state.caseInformation.AccessToDocuments != false || this.state.caseInformation.AccessToUploadDocuments != false) &&
                                <div className="item">
                                    {this.state.caseInformation.AccessToDocuments &&
                                        <div>
                                           
                                            <h2 className="w-100 d-flex align-items-center justify-content-between mb-3">
                                                <span>{this.props.t('caseDetails.documents')} </span>
                                                <Link to={{ pathname: "/DocumentDetails", state: { caseId: this.state.caseInformation.Id } }}
                                                    className="smallbtn">{this.props.t('caseDetails.viewAllDocument')}</Link>
                                            </h2>
                                            {this.state.documents.map((key, i) => (
                                                <ul key={i} className={key.IsLatestDocument ? "documents_list newDocument" : "documents_list"}>
                                                    <li>
                                                            <div>
                                                                <OverlayTrigger
                                                                    key={"top"}
                                                                    placement={"top"}
                                                                    overlay={
                                                                        <Tooltip id={i}>
                                                                             {`${key.Author} ${key.relativeUrl.split('/').slice(4).join('/')}`}.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon className="documentCategory color1" icon={key.Author === "Lawyer" ? faLandmark : faUser} />
                                                                </OverlayTrigger>
                                                                <span>{key.Name} {key.IsLatestDocument ? <Badge className="ml-2 newbage">New</Badge> : ""}</span>
                                                                <span>{moment(key.CreatedDate).format(dateTimeFormat.dateFormat)}</span>
                                                                <a className="fileDownload"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => { this.handleDownload(key.DocumentUrl, key.Name) }}
                                                                >
                                                                    <OverlayTrigger
                                                                        key={"top"}
                                                                        placement={"top"}
                                                                        overlay={
                                                                            <Tooltip id={`d-${i}`}>
                                                                                {this.props.t('caseDetails.downloadTooltip')}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon className="color1" icon={faDownload} />
                                                                    </OverlayTrigger>
                                                                </a>
                                                            </div>
                                                    </li>
                                                </ul>
                                            ))
                                            }
                                        </div>
                                    }
                                    {this.state.caseInformation.AccessToUploadDocuments &&
                                        <div className="fileUploader mt-5">
                                            <Dropzone
                                                getUploadParams={this.getUploadParams}
                                                onChangeStatus={this.handleChangeStatus}
                                                onSubmit={this.handleSubmitFileUploader}
                                                PreviewComponent={Preview}
                                                autoUpload={false}
                                                SubmitButtonComponent={SubmitButton}
                                                disabled={files => files.some(f => ['preparing', 'getting_upload_params'].includes(f.meta.status))}
                                                accept=".doc, .docx, .pdf, .xlsx, .xls"
                                                inputContent={this.props.t('caseDetails.dropZoneMessage')}
                                                inputWithFilesContent={this.props.t('caseDetails.documentAddFiles')}
                                                styles={{
                                                    dropzone: { minHeight: 200, maxHeight: 250, overflow: "auto", backgroundColor: "transparent", border: "2px dashed #122e43" },
                                                    inputLabel: { fontFamily: "'PT Sans', sans-serif", color: "#122e43" },
                                                    dropzoneActive: { boxShadow: "0px 0px 45px rgba(70,130,180,1)" }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    casedetails: state.user.caseDetails,
    createthread: state.user.createThread,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    getCaseDetail,
    createThread,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CaseDetails)));