import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
// Language translate import
import { withTranslation } from 'react-i18next';

class SurveyBoolen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null, 
    };
  }

  componentDidMount() {
    const { data, response } = this.props;
    const questionId = data.questionId;


    if (response && response.SurveyResponses) {
      const matchedAnswer = response.SurveyResponses.find(answer => answer.questionId === questionId);
      if (matchedAnswer) {
        this.setState({
          text: matchedAnswer.answer === this.props.t('survey.no') ?false:true
        });
        this.props.setUpdateRadio({ "required": this.props.data.isMandatoryQuestion, "data": matchedAnswer.answer }, this.props.uid)
        setTimeout(() => {
          var elements = document.querySelectorAll("div[id$='" + matchedAnswer.questionId + "']");

          if (elements) {
            elements.forEach((element) => {
              if (element) {
                if (matchedAnswer.answer === this.props.t('survey.no')) {
                  element.style.display = 'none';
                } else {
                  element.style.display = 'block';
                }
              }
            });
          }
        }, 0);
      }
    }
  }
 

  handleOnChangeRadio = (e) => {
    const { data, setUpdateRadio, uid } = this.props;
    const isYes = e.target.value === this.props.t('survey.yes'); 
    this.setState({ text: isYes });

    setUpdateRadio({
      required: data.isMandatoryQuestion,
      data: e.target.value,
      questionId: data.questionId,
    }, uid);

    
    
  };

  render() {
    const { t, data } = this.props;
    const { text } = this.state;

    return (
      <Form.Group onChange={this.handleOnChangeRadio}>
        <div className="surveyquestions" id={data.relatedToQuestionId || ""} >
          <p>
            {data.question}
            {data.isMandatoryQuestion ? <SurveyRequired /> : ""}
            {data.questionTooltip && <Surveytooltip text={data.questionTooltip} />}
          </p>
          <Form.Check
            type="radio"
            label={t('survey.yes')}
            name={`${data.questionId}`}
            id={`${data.questionId}1`}
            value={t('survey.yes')}
            checked={text === true}
          />
          <Form.Check
            type="radio"
            label={t('survey.no')}
            name={`${data.questionId}`}
            id={`${data.questionId}2`}
            value={t('survey.no')}
            checked={text === false} 
          />
        </div>
      </Form.Group>
    );
  }
}

export default withTranslation('common')(SurveyBoolen);