import React, { Component } from 'react';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
import Form from 'react-bootstrap/Form';
import ReactStars from "react-rating-stars-component";

export default class Survey10Star extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingvalue: 0 
        };
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        const question = this.props.data.questionId
        const response = this.props.response
     
        const matchResponse = response?.SurveyResponses?.find(questionid => questionid.questionId === question)
       

        if (matchResponse) {
            const matchedAnswer = response?.SurveyResponses.find(answer => answer.questionId === question);

            if (matchedAnswer) {
                this.setState({ ratingvalue: Number(matchedAnswer.answer) }, () => {             
                  
                });
            }
            this.props.setUpdate10StarRating({ "required": this.props.data.isMandatoryQuestion, "data": matchedAnswer.answer }, this.props.uid)
        }
    }

    handlerating = (e) => {
        this.props.setUpdate10StarRating({ "required": this.props.data.isMandatoryQuestion, "data": e }, this.props.uid);
    };

    render() {
       
        
        return (
            <Form.Group>
                <div className="surveyquestions" id={this.props.data.relatedToQuestionId ? this.props.data.relatedToQuestionId : ""}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <p>
                                {this.props.data.question}
                                {this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                                {this.props.data.questionTooltip !== "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                            </p>
                            <ReactStars
                                count={10}
                                onChange={this.handlerating}
                                size={35}
                                activeColor="#ffd700"
                                value={this.state.ratingvalue}
                                key={this.state.ratingvalue}
                            />
                        </div>
                    </div>
                </div>
            </Form.Group>
        );
    }
}