import React, { Component } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset, getPendingSurveyList } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';


class SurveyList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            surveyList: []
        }
    }
    componentDidMount() {

    }
    componentWillMount() {
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication) {
                this.props.history.push('/Dashboard')
            } else {               
                const clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
                const getSurveyParams = `?clientId=${clientId}`;
                this.props.getPendingSurveyList(getSurveyParams)
            }
        }
    }

    componentWillReceiveProps(nextprops) {
        //if (Object.keys(nextprops.pendingSurveyList).length > 0) {
        if (nextprops.surveylist !== this.props.surveylist) {
            const surveyData = nextprops.surveylist;
            if (surveyData.Success) {
                this.setState({
                    surveyList: surveyData.Result.LegalSurveys,
                })
            } else {
                errorToast(surveyData.Message)
            }
        }
       ;
        
    }

    render() {
        if (this.props.fetching && !this.props.error) {
            return (<Loading />)
        }
        return (
            <div className="Dashboard">
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area-details alignedBox">
                            <div className="col-md-12">
                                <h1 className="ml-3 mb-3 case-title">{this.props.t('survey.surveyTitle')}</h1>
                                <div className="item">
                                    {this.state.surveyList.length === 0 ?
                                        <ul className="MessageList">
                                            <li>
                                                <span>{this.props.t('survey.emptySurveyList')}</span>
                                            </li>
                                        </ul>
                                        :
                                        this.state.surveyList.map((key, i) => (
                                            <ul key={i} className="MessageList">
                                                <Link to={{
                                                    pathname: "/Survey",
                                                    state: {
                                                        description: key.description,
                                                        isRequired: key.isRequired,
                                                        name: key.name,
                                                        surveyId: key.surveyId
                                                    }
                                                }}>
                                                    <li>      
                                                        <div className="font-weight-bold color1">
                                                            <FontAwesomeIcon className="surveymessageicon color3" style={{ fontSize: "25px" }} icon={faClipboardList} />
                                                            <div dangerouslySetInnerHTML={{__html: key.name}} />
                                                        </div>

                                                        <p>{key.description}</p>
                                                    </li>
                                                </Link>
                                            </ul>
                                        ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    surveylist: state.user.pendingSurveyList,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    getPendingSurveyList,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SurveyList)));