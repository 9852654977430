import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';

export default class SurveySingleChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: [] 
        };
    }

    componentDidMount() {
        const { questionId } = this.props.data;
        const { response } = this.props;

        const matchResponse = response?.SurveyResponses.find(res => res.questionId === questionId);

        if (matchResponse) {
            this.setState({ selectedValue: matchResponse.answer });
            this.props.setUpdateValue({ 
                "required": this.props.data.isMandatoryQuestion, 
                "data": matchResponse.answer 
            }, this.props.uid);
        }
    }

    handleOnChangeValues = (e) => {
        const selectedValue = e.target.value;
        this.setState({ selectedValue }, () => {
            this.props.setUpdateValue({
                "required": this.props.data.isMandatoryQuestion,
                "data": selectedValue
            }, this.props.uid);
        });
    }

    render() {
        const { question, isMandatoryQuestion, questionTooltip, multipleChoiceOptions, relatedToQuestionId } = this.props.data;

        return (
            <Form.Group onChange={this.handleOnChangeValues}>
                <div className="surveyquestions" id={relatedToQuestionId || ""}>
                    <p>
                        {question}
                        {isMandatoryQuestion && <SurveyRequired />}
                        {questionTooltip && <Surveytooltip text={questionTooltip} />}
                    </p>

                    {multipleChoiceOptions.map((key, i) => (
                        <Form.Check 
                            key={i}
                            type="radio"
                            label={key.trim()}
                            name="single"
                            id={`formHorizontalRadios${i}${key}`}
                            value={key.trim()}
                            checked={this.state.selectedValue === key.trim()}
                        />
                    ))}
                </div>
            </Form.Group>
        );
    }
}
