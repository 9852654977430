import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';

export default class SurveyMCQ extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            selectedMCQ:[]
        }
    }
    componentDidMount() {
        const { data, response } = this.props;
        const questionId = data.questionId;

        if (response && response.SurveyResponses) {
            const matchedAnswer = response.SurveyResponses.find(answer => answer.questionId === questionId);
            if (matchedAnswer) {
                // Initialize selectedMCQ with previously selected answers (if any)
                this.setState({ selectedMCQ: matchedAnswer.answer ? matchedAnswer.answer.split(',') : [] }, () => {
                    // Notify parent component of the initial selected values
                    this.props.setUpdateMCQ({
                        required: data.isMandatoryQuestion,
                        data: this.state.selectedMCQ
                    }, this.props.uid);
                });
            }
        }
    }

    handleOnChangeMCQ = (e) => {
        const { value, checked } = e.target;
        this.setState(prevState => {
            let updatedMCQ;
            if (checked) {

                updatedMCQ = [...prevState.selectedMCQ, value];
            } else {
                updatedMCQ = prevState.selectedMCQ.filter(item => item !== value);
            }

            this.props.setUpdateMCQ({
                required: this.props.data.isMandatoryQuestion,
                data: updatedMCQ
            }, this.props.uid);

            return { selectedMCQ: updatedMCQ };
        });
    };

    render() {
        return (
            <Form.Group
                onChange={this.handleOnChangeMCQ}
            >
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                        {this.props.data.questionTooltip != ""? <Surveytooltip text={this.props.data.questionTooltip}/> : ""}
                    </p>
                    {this.props.data.multipleChoiceOptions.map((key,i)=>(
                        <Form.Check key={i}
                        type="checkbox"
                        label={key.trim()}
                        name={`formHorizontalRadios${i}${key}`}
                        id={`formHorizontalRadios${i}${key}`}
                            value={key.trim()}
                            checked={this.state.selectedMCQ.includes(key.trim())}
                    />
                    ))} 
                </div>
            </Form.Group>
        )
    }
}
