// Vipps Login Constants

export const PORTALLOGO_REQUEST = 'PORTALLOGO_REQUEST';
export const PORTALLOGO_SUCCESS = 'PORTALLOGO_SUCCESS';
export const PORTALLOGO_FAIL = 'PORTALLOGO_FAIL';


// User constants
export const VIPPSAUTHURL_REQUEST = 'VIPPSAUTHURL_REQUEST';
export const VIPPSAUTHURL_SUCCESS = 'VIPPSAUTHURL_SUCCESS';
export const VIPPSAUTHURL_FAIL = 'VIPPSAUTHURL_FAIL';

// User constants
export const USERINFO_REQUEST = 'USERINFO_REQUEST';
export const USERINFO_SUCCESS = 'USERINFO_SUCCESS';
export const USERINFO_FAIL = 'USERINFO_FAIL';

// User constants
export const BANKIDURL_REQUEST = 'BANKIDURL_REQUEST';
export const BANKIDURL_SUCCESS = 'BANKIDURL_SUCCESS';
export const BANKIDURL_FAIL = 'BANKIDURL_FAIL';

// User constants
export const BANKIDUSERINFO_REQUEST = 'BANKIDUSERINFO_REQUEST';
export const BANKIDUSERINFO_SUCCESS = 'BANKIDUSERINFO_SUCCESS';
export const BANKIDUSERINFO_FAIL = 'BANKIDUSERINFO_FAIL';


// User Save Consent
export const USER_CONSENTSAVE_REQUEST = 'USER_CONSENTSAVE_REQUEST';
export const USER_CONSENTSAVE_SUCCESS = 'USER_CONSENTSAVE_SUCCESS';
export const USER_CONSENTSAVE_FAIL = 'USER_CONSENTSAVE_FAIL';

// Login news
export const LOGIN_NEWS_REQUEST = 'LOGIN_NEWS_REQUEST';
export const LOGIN_NEWS_SUCCESS = 'LOGIN_NEWS_SUCCESS';
export const LOGIN_NEWS_FAIL = 'LOGIN_NEWS_FAIL';

// Dashboard news
export const DASHBOARD_NEWS_REQUEST = 'DASHBOARD_NEWS_REQUEST';
export const DASHBOARD_NEWS_SUCCESS = 'DASHBOARD_NEWS_SUCCESS';
export const DASHBOARD_NEWS_FAIL = 'DASHBOARD_NEWS_FAIL';

//News
export const NEWS_REQUEST = 'NEWS_REQUEST';
export const NEWS_SUCCESS = 'NEWS_SUCCESS';
export const NEWS_FAIL = 'NEWS_FAIL';

//Area
export const AREA_REQUEST = 'AREA_REQUEST';
export const AREA_SUCCESS = 'AREA_SUCCESS';
export const AREA_FAIL = 'AREA_FAIL';

//RelationShips
export const RELATIONSHIP_REQUEST = 'RELATIONSHIP_REQUEST';
export const RELATIONSHIP_SUCCESS = 'RELATIONSHIP_SUCCESS';
export const RELATIONSHIP_FAIL = 'RELATIONSHIP_FAIL';

//Branches
export const BRANCHES_REQUEST = 'BRANCHES_REQUEST';
export const BRANCHES_SUCCESS = 'BRANCHES_SUCCESS';
export const BRANCHES_FAIL = 'BRANCHES_FAIL';

//Lawyer
export const LAWYER_REQUEST = 'LAWYER_REQUEST';
export const LAWYER_SUCCESS = 'LAWYER_SUCCESS';
export const LAWYER_FAIL = 'LAWYER_FAIL';

//booking
export const BOOKING_REQUEST = 'BOOKING_REQUEST';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_FAIL = 'NEWS_FAIL';

//Profile Save
export const PROFILE_SAVE_REQUEST = 'PROFILE_SAVE_REQUEST';
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS';
export const PROFILE_SAVE_FAIL = 'PROFILE_SAVE_FAIL';

//Profile Get
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';


//All case
export const ALL_CASE_REQUEST = 'ALL_CASE_REQUEST';
export const ALL_CASE_SUCCESS = 'ALL_CASE_SUCCESS';
export const ALL_CASE_FAIL = 'ALL_CASE_FAIL';

//caseDetails
export const CASE_DETAIL_REQUEST = 'CASE_DETAIL_REQUEST';
export const CASE_DETAIL_SUCCESS = 'CASE_DETAIL_SUCCESS';
export const CASE_DETAIL_FAIL = 'CASE_DETAIL_FAIL';

//documentList
export const DOCUMENT_LIST_REQUEST = 'DOCUMENT_LIST_REQUEST';
export const DOCUMENT_LIST_SUCCESS = 'DOCUMENT_LIST_SUCCESS';
export const DOCUMENT_LIST_FAIL = 'DOCUMENT_LIST_FAIL';

//Create Thread
export const CREATE_THREAD_REQUEST = 'CREATE_THREAD_REQUEST';
export const CREATE_THREAD_SUCCESS = 'CREATE_THREAD_SUCCESS';
export const CREATE_THREAD_FAIL = 'CREATE_THREAD_FAIL';

//messageList
export const THREAD_LIST_REQUEST = 'THREAD_LIST_REQUEST';
export const THREAD_LIST_SUCCESS = 'THREAD_LIST_SUCCESS';
export const THREAD_LIST_FAIL = 'THREAD_LIST_FAIL';

//Chat Message
export const CHAT_REQUEST = 'CHAT_REQUEST';
export const CHAT_SUCCESS = 'CHAT_SUCCESS';
export const CHAT_FAIL = 'CHAT_FAIL';

export const CREATE_CHAT_REQUEST = 'CREATE_CHAT_REQUEST';
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS';
export const CREATE_CHAT_FAIL = 'CREATE_CHAT_FAIL';

export const UPDATE_CHAT_REQUEST = 'UPDATE_CHAT_REQUEST';
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS';
export const UPDATE_CHAT_FAIL = 'UPDATE_CHAT_FAIL';

// Get pending surveys
export const PENDING_SURVEY_REQUEST = 'PENDING_SURVEY_REQUEST';
export const PENDING_SURVEY_SUCCESS = 'PENDING_SURVEY_SUCCESS';
export const PENDING_SURVEY_FAIL = 'PENDING_SURVEY_FAIL';

// Get pending surveys
export const GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAIL = 'GET_SURVEY_FAIL';

// Get public surveys
export const GET_PUBLICSURVEY_REQUEST = 'GET_PUBLICSURVEY_REQUEST';
export const GET_PUBLICSURVEY_SUCCESS = 'GET_PUBLICSURVEY_SUCCESS';
export const GET_PUBLICSURVEY_FAIL = 'GET_PUBLICSURVEY_FAIL';

// Post surveys
export const POST_SURVEY_REQUEST = 'POST_SURVEY_REQUEST';
export const POST_SURVEY_SUCCESS = 'POST_SURVEY_SUCCESS';
export const POST_SURVEY_FAIL = 'POST_SURVEY_FAIL';

export const SURVEYCALLBACK_REQUEST = 'SURVEYCALLBACK_REQUEST';
export const SURVEYCALLBACK_SUCCESS = 'SURVEYCALLBACK_SUCCESS';
export const SURVEYCALLBACK_FAIL = 'SURVEYCALLBACK_FAIL';

// RESET 
export const RESET = 'RESET';

// Error
export const ISERRORSERVER = 'ISERRORSERVER';

// Create Case
export const CREATE_CASE_SUCCESS = "CREATE_CASE_SUCCESS";
export const CREATE_CASE_REQUEST = "CREATE_CASE_REQUEST";
export const CREATE_CASE_FAIL = "CREATE_CASE_FAIL";

// Check Valid MarketSourse
export const CHECK_GUID_SUCCESS = "CHECK_GUID_SUCCESS";
export const CHECK_GUID_REQUEST = "CHECK_GUID_REQUEST";
export const CHECK_GUID_FAIL = "CHECK_GUID_FAIL";

// Survey Response
export const SURVEY_RESPONSE_REQUEST = "SURVEY_RESPONSE_REQUEST";
export const SURVEY_RESPONSE_SUCCESS = "SURVEY_RESPONSE_SUCCESS";
export const SURVEY_RESPONSE_FAIL = "SURVEY_RESPONSE_FAIL";

// Store Survey Response
export const STORE_SURVEY_REQUEST = "STORE_SURVEY_REQUEST";
export const STORE_SURVEY_SUCCESS = "STORE_SURVEY_SUCCESS";
export const STORE_SURVEY_FAIL = "STORE_SURVEY_FAIL";

// Public Survey Response

export const PUBLIC_SURVEY_RESPONSE_REQUEST = "PUBLIC_SURVEY_RESPONSE_REQUEST";
export const PUBLIC_SURVEY_RESPONSE_SUCCESS = "PUBLIC_SURVEY_RESPONSE_SUCCESS";
export const PUBLIC_SURVEY_RESPONSE_FAIL    = "PUBLIC_SURVEY_RESPONSE_FAIL";

