import React, { Component } from 'react';
import "../../assets/css/login.css"
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
// Language translate import
import { withTranslation } from 'react-i18next';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { userInfo } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage';
import { surveyOnLogin } from '../../utils/restriction';
class LoginRedirect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: "",
            portalLogo: localStorage.getItem("portalLogo")
        }
    }

    componentWillMount() {
        const urlParams = this.props.location.search;
        if (urlParams) {
            const accessTokenParam = new URLSearchParams(urlParams);
            if (accessTokenParam) {
                const userinfo_body = {
                    "code": accessTokenParam.get('code'),
                    "createCustomerIfNotExist": window.globalConfig.CreateCustomerIfNotExist==="false"?false:true
                }
                this.props.userInfo(userinfo_body);
            }
            else {
                this.props.history.push('/')
            }

        } else {
            this.props.history.push('/')
        }
        this.setState({
            portallogo: localStorage.getItem("portalLogo")
        })

    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.userinformation).length > 0) {
            const userInformation = nextprops.userinformation
            if (userInformation.Success) {
                if (userInformation.Result.isClientAuthenticated) {
                    if (userInformation.Result.isOnboardingRequired) {
                        this.props.history.push({
                            pathname: '/OnBoarding',
                            state: {
                                email: userInformation.Result.email,
                                phoneNumber: userInformation.Result.phone_number,
                                name: userInformation.Result.name
                            }
                        })
                    } else {
                        if (userInformation.Result.survey !== null) {
                            surveyOnLogin(this.props, userInformation.Result.survey.surveyId)
                            return
                        }
                        if (sessionStorage.getItem("PreviousPath")) {
                            this.props.history.push(`${sessionStorage.getItem("PreviousPath")}`)
                        } else {
                            const data = localStorage.getItem("sideBar");
                            if (data !== undefined && data !== "" && data !== null) {
                                let SideBar = JSON.parse(data);
                                let sideBarConfig = SideBar.filter(obj => obj.includes("active"));
                                let activesideBarConfig = sideBarConfig[0].split(":")[0] ? sideBarConfig[0].split(":")[0] : "";
                                activesideBarConfig ? this.props.history.push(activesideBarConfig) : this.props.history.push("Dashboard");

                            }
                            else {
                                this.props.history.push('Dashboard')
                            }

                        }
                    }
                } else {
                    if (userInformation.Result.survey !== null) {
                        surveyOnLogin(this.props, userInformation.Result.survey.surveyId)
                        return
                    }
                    if (sessionStorage.getItem("PreviousPath")) {
                        
                        this.props.history.push(`${sessionStorage.getItem("PreviousPath")}`)
                    } else {
                        
                        this.props.history.push('/Dashboard')
                    }
                }
            } else {
                this.setState({
                    error: this.props.t('loginRedirect.errorText')
                })
            }
        }

    }

    render() {
        if (this.props.error) {
            return <ErrorPage />;
        }
        return (
            <div className={"login-window-" + window.globalConfig.CustomerName} id="loginPage" >
                {this.props.fetching && <Loading />}
                <div className="login-area rounded login-redirect-area">
                    <div className="login-column w-100">
                        <p><img src={this.state.portallogo} alt="Legel365" /></p>
                        <div className="login-ftr mt-5">
                            <p>{this.props.t('loginRedirect.infoText')}</p>
                        </div>
                        <p style={{ color: "red" }}>{this.state.error}</p>
                        {
                            this.state.error != "" ?
                                <p className="fnt-14"><Link className="vippsbtn mt-2 d-block" to="/"> {this.props.t('login.backLink')} </Link></p>
                                : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userinformation: state.user.userInfo,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    userInfo,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(LoginRedirect)));