import React, { Component } from 'react'
import Surveytooltip from './SurveyTooltip';
import Form from 'react-bootstrap/Form';
import SurveyRequired from './SurveyRequired';
export default class SurveyTextArea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            text:""
        }
        
    }

    componentDidMount() {
        const question = this.props.data.questionId
        const response = this.props.response
        const matchResponse = response?.SurveyResponses?.find(questionid => questionid.questionId === question)
       
        
        if (matchResponse) {
            this.setState({
                text: matchResponse.answer,
            })
            this.props.setUpdateTextArea({"required":this.props.data.isMandatoryQuestion,"data":matchResponse.answer},this.props.uid)
        }
    }
       
    
    handleTextArea = (e) =>{
        this.setState({
            text: e.target.value
        })
    }

    handleTextUpdate = () => {
        if (this.state.text) {
            
            this.props.setUpdateTextArea({"required":this.props.data.isMandatoryQuestion,"data":this.state.text},this.props.uid)
        }
    }
    render() {

        return (
            <Form.Group>
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""} >
                    <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                        {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                    </p>
                    <textarea className="form-control" id={this.props.questionId} rows="3" 
                    onChange={this.handleTextArea}
                    value={this.state.text}
                        onBlur={this.handleTextUpdate}
                        ref={this.textareaRef}
                    ></textarea>
                </div>
            </Form.Group>
        )
    }
}
