import React, { Component } from "react";
import Button from "react-bootstrap/Form";
import SurveyMCQ from "../Survey/SurveyMCQ";
import SurveyBoolen from "../Survey/SurveyBoolen";
import SurveyTextArea from "../Survey/SurveyTextArea";
import SurveyDatepicker from "../Survey/SurveyDatepicker";
import SurveyNumber from "./SurveyNumber";
import SurveySingleLineText from "./SurveySinglelinetext";
import Survey6Stars from "./Survey6Stars";
import Survey10Stars from "./Survey10Stars";
import { withRouter } from "react-router";
import { infoToast } from "../Notification/Notification";
import { withTranslation } from "react-i18next";
import { postSurvey,storeSurvey} from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import SurveySingleChoice from "./SurveySingleChoice";

class RenderSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: [],
      date: [],
      textarea: [],
      singlelinetext: [],
      boolean: [],
      multipleChoice: [],
      singleChoice: [],
      requireFields: false,
      requireQuestionCheck: [],
      rating1to6: [],
      rating1to10: [],
      storeButtonRequireFields: false,
    
    };
  }

  componentDidMount() {
    let questions = this.props.question;
    questions.forEach((data, index) => {
      var elements = document.querySelectorAll("div[id$='" + data.questionId + "']");
      if (elements) {
        elements.forEach((element, index) => {
          if (element) {
            element.style.display = 'none';
          }
        });
      }
    });
  }

  afterValidationCheckSubmit = (ArrayData) => {
    if (!this.state.requireFields) {
      const responseData = [];
      ArrayData.forEach((element, index) => {
        responseData.push({
          questionId:element["questionId"],
          question: element["name"],
          answer: element["data"].data.toString(),
          responseType:element["responseType"]
        });
      });
      if (this.props.isPublicSurvey) {
        setTimeout(() => {
          const surveyobj = {
            surveyId: this.props.survey.surveyId,
            phoneNumber: "",
            isBankIdSigning: this.props.survey.isSigningByBankId,
            surveyName: this.props.survey.surveyName,
            responses: responseData,
            logo: localStorage.getItem("publicportallogo")
          };
          this.props.postSurvey(surveyobj);
         
          
          
          
        }, 500);
      } else {
        setTimeout(() => {
          const surveyobj = {
            surveyId: this.props.survey.surveyId,
            phoneNumber: JSON.parse(localStorage.getItem("UserInfo")).phone_number,
            clientId: JSON.parse(localStorage.getItem("UserInfo")).userId,
            dynamicsEntity: localStorage.getItem("dynamicsEntity"),
            surveyName: this.props.survey.surveyName,
            isBankIdSigning: this.props.survey.isSigningByBankId,
            responses: responseData,
            logo: localStorage.getItem("portalLogo")
          };
         this.props.postSurvey(surveyobj);
    
          
        }, 500);
      }
    }
  };

  afterValidationCheckStore = (ArrayData) => {

      const responseData = [];
      ArrayData.forEach((element, index) => {
        responseData.push({
          questionId:element["questionId"],
          question: element["name"],
          answer: element["data"].data.toString(),
          responseType:element["responseType"]
        });
      });
      if (this.props.isPublicSurvey) {
        setTimeout(() => {
          const surveyobj = {
            surveyId: this.props.survey.surveyId,
            phoneNumber: "",
            isBankIdSigning: this.props.survey.isSigningByBankId,
            surveyName: this.props.survey.surveyName,
            responses: responseData,
            logo: localStorage.getItem("publicportallogo")
          };
          this.props.storeSurvey(surveyobj);
         
          
          
          
        }, 500);
      } else {
        setTimeout(() => {
          const surveyobj = {
            surveyId: this.props.survey.surveyId,
            phoneNumber: JSON.parse(localStorage.getItem("UserInfo")).phone_number,
            clientId: JSON.parse(localStorage.getItem("UserInfo")).userId,
            dynamicsEntity: localStorage.getItem("dynamicsEntity"),
            surveyName: this.props.survey.surveyName,
            isBankIdSigning: this.props.survey.isSigningByBankId,
            responses: responseData,
            logo: localStorage.getItem("portalLogo")
          };
          this.props.storeSurvey(surveyobj);
        }, 500);
      }
    
  };

  handleSubmit = () => {
    const finalArray = [];
    const requiredQuestionsAnsweredArray = [];
    let requiredQuestionsArray = [];
    this.props.question.forEach((element, index) => {
     
      switch (element.responseType) {
        case "Yes/No":
          if (this.state.boolean[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.boolean[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId;
            finalArray.push(question);
          }
          break;
        case "Text":
          if (this.state.textarea[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.textarea[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId;
            finalArray.push(question);
          }

          break;
        case "Date":
          if (this.state.date[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.date[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId;
            finalArray.push(question);
          }
          break;
        case "Multiple Choice":
          if (this.state.multipleChoice[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.multipleChoice[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId;
            finalArray.push(question);
          }
          break;
        case "Single Choice":
          if (this.state.singleChoice[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.singleChoice[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId;
            finalArray.push(question);
          }
          break;
        case "Amount":
        case "Whole Number":
          if (this.state.amount[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.amount[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId;
            finalArray.push(question);
          }
          break;
        case "SingleLineText":
          if (this.state.singlelinetext[`que-${index}`] !== undefined) {
            if (this.state.singlelinetext[`que-${index}`].data) {
              let question=[];
              question["data"]=this.state.singlelinetext[`que-${index}`];
              question["name"]=element.question;
              question["responseType"] = element.responseType;
              question["questionId"] = element.questionId;
              finalArray.push(question);
            }
          }
        case "Rating1to6":
          if (this.state.rating1to6[`que-${index}`] !== undefined) {
            if (this.state.rating1to6[`que-${index}`].data) {
              let question=[];
              question["data"]=this.state.rating1to6[`que-${index}`];
              question["name"]=element.question;
              question["responseType"] = element.responseType;
              question["questionId"] = element.questionId;
              finalArray.push(question);
            }
          }
        case "Rating1to10":
          if (this.state.rating1to10[`que-${index}`] !== undefined) {
            if (this.state.rating1to10[`que-${index}`].data) {
              let question=[];
              question["data"]=this.state.rating1to10[`que-${index}`];
              question["name"]=element.question;
              question["responseType"] = element.responseType;
              question["questionId"] = element.questionId;
              finalArray.push(question);
            }
          }
          break;
        default:
          break;
      }
    });


    if (this.props.requireQuestionCheck.length > 0) {
      this.props.requireQuestionCheck.forEach((element) => {
        if (element == true) {
          requiredQuestionsArray.push(element);
        }
      });
    }
    this.props.question.forEach((element) => {
      if (element.relatedToQuestionId !== "00000000-0000-0000-0000-000000000000") {
        var ele = document.getElementsByName(element.relatedToQuestionId);
        ele.forEach((e) => {
          if (e.checked) {
            if (e.value === this.props.t('survey.yes') && element.isMandatoryQuestion)
              requiredQuestionsArray.push(element.isMandatoryQuestion);
          }

        });
      }
    });
    if (finalArray.length > 0) {
      finalArray.forEach((element) => {
        if (element["data"].required) {
          if (element["data"].data &&element["data"].data.length!==0) {
            requiredQuestionsAnsweredArray.push("Answered");
          }
        }
      });
    }

    if (requiredQuestionsArray.length == requiredQuestionsAnsweredArray.length) {
      setTimeout(() => {
        this.afterValidationCheckSubmit(finalArray);
      }, 1000);
    }
    else {
      this.setState({
        requireFields: true,
      });
    }
  };
  
  handleStore = () => {
    const finalArray = [];
    const requiredQuestionsAnsweredArray = [];
    const requiredQuestionsArray = [];
    this.props.question.forEach((element, index) => {
      switch (element.responseType) {
        case "Yes/No":
          if (this.state.boolean[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.boolean[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId
            finalArray.push(question);
          }
          break;
        case "Text":
          if (this.state.textarea[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.textarea[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId
            finalArray.push(question);
          }

          break;
        case "Date":
          if (this.state.date[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.date[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId
            finalArray.push(question);
          }
          break;
        case "Multiple Choice":
          if (this.state.multipleChoice[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.multipleChoice[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId
            finalArray.push(question);
          }
          break;
        case "Single Choice":
          if (this.state.singleChoice[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.singleChoice[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId
            finalArray.push(question);
          }
          break;
        case "Amount":
        case "Whole Number":
          if (this.state.amount[`que-${index}`] !== undefined) {
            let question=[];
            question["data"]=this.state.amount[`que-${index}`];
            question["name"]=element.question;
            question["responseType"] = element.responseType;
            question["questionId"] = element.questionId
            finalArray.push(question);
          }
          break;
        case "SingleLineText":
          if (this.state.singlelinetext[`que-${index}`] !== undefined) {
            if (this.state.singlelinetext[`que-${index}`].data) {
              let question=[];
              question["data"]=this.state.singlelinetext[`que-${index}`];
              question["name"]=element.question;
              question["responseType"] = element.responseType;
              question["questionId"] = element.questionId
              finalArray.push(question);
            }
          }
        case "Rating1to6":
          if (this.state.rating1to6[`que-${index}`] !== undefined) {
            if (this.state.rating1to6[`que-${index}`].data) {
              let question=[];
              question["data"]=this.state.rating1to6[`que-${index}`];
              question["name"]=element.question;
              question["responseType"] = element.responseType;
              question["questionId"] = element.questionId
              finalArray.push(question);
            }
          }
        case "Rating1to10":
          if (this.state.rating1to10[`que-${index}`] !== undefined) {
            if (this.state.rating1to10[`que-${index}`].data) {
              let question=[];
              question["data"]=this.state.rating1to10[`que-${index}`];
              question["name"]=element.question;
              question["responseType"] = element.responseType;
              question["questionId"] = element.questionId
              finalArray.push(question);
            }
          }
          break;
        default:
          break;
      }
    });

    if (this.props.requireQuestionCheck.length > 0) {
      this.props.requireQuestionCheck.forEach((element) => {
        if (element == true  ) {
          requiredQuestionsArray.push(element);
        }
      });
    }
    this.props.question.forEach((element) => {
      if (element.relatedToQuestionId !== "00000000-0000-0000-0000-000000000000") {
        var ele = document.getElementsByName(element.relatedToQuestionId);
        ele.forEach((e) => {
          if (e.checked) {
            if (e.value === this.props.t('survey.yes'))
              requiredQuestionsArray.push(element.isMandatoryQuestion);
          }

        });
      }
    });
    if (finalArray.length > 0) {
      finalArray.forEach((element) => {
        if (element["data"].required) {
          if (element["data"].data !== null && element["data"].data !== "" && element["data"].data !== undefined) {
            requiredQuestionsAnsweredArray.push("Answered");
          }
        } else {
         
          if (element["data"].data !== null && element["data"].data !== "" && element["data"].data !== undefined) {
            requiredQuestionsAnsweredArray.push("Answered");
          }
        }
      });
    }

    if (requiredQuestionsAnsweredArray.length >0) {
      setTimeout(() => {
        this.afterValidationCheckStore(finalArray);
      }, 1000);
    }
    else {
      this.setState({
        storeButtonRequireFields: true,
      });
    }
  }

  handleSkip = () => {
    if (this.props.survey.isRequired) {
      infoToast(this.props.t('survey.skipText'))
    } else {
      this.props.history.push("/SurveyList")
    }
  }

  updatehandleMCQ = (multipleChoiceData, uid) => {
    const newData = this.state.multipleChoice;
    newData[`que-${uid}`] = multipleChoiceData;

    this.setState({
      multipleChoice: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };
  updatehandleSingleChoice = (singleChoiceData, uid) => {
    const newData = this.state.singleChoice;
    newData[`que-${uid}`] = singleChoiceData;

    this.setState({
      singleChoice: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };
  updatehandleRating1to6 = (ratingData, uid) => {
    const newData = this.state.rating1to6;
    newData[`que-${uid}`] = ratingData;
    this.setState({
      rating1to6: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };
  updatehandleRating1to10 = (ratingData, uid) => {

    const newData = this.state.rating1to10;
    newData[`que-${uid}`] = ratingData;

    this.setState({
      rating1to10: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };
  updatehandleAmount = (amount, uid) => {
    const newData = this.state.amount;
    newData[`que-${uid}`] = amount;

    this.setState({
      amount: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };

  updatehandleWhole = (wholeNo, uid) => {
    const newData = this.state.amount;
    newData[`que-${uid}`] = wholeNo;

    this.setState({
      amount: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };

  updatehandleTextArea = (text, uid) => {
    const newData = this.state.textarea;
   
    
    newData[`que-${uid}`] = text;
    this.setState({
      textarea: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };
  updatesinglelinetext = (singlelinetext, uid) => {
    const newData = this.state.singlelinetext;
    newData[`que-${uid}`] = singlelinetext;
    this.setState({
      singlelinetext: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });

  };

  updatehandleDate = (date, uid) => {
    const newData = this.state.date;
    newData[`que-${uid}`] = date;

    this.setState({
      date: newData,
      requireFields: false,
      storeButtonRequireFields:false
    });
  };

  updatehandleBoolean = (info, uid) => {
    let questions = this.props.question;
    questions.forEach((data, index) => {
      if (info.questionId === data.questionId) {
        var elements = document.querySelectorAll("div[id$='" + data.questionId + "']");
        if (elements) {
          elements.forEach((element, index) => {
            if (element) {
              if (info.data === this.props.t('survey.no')) {
                element.style.display = 'none';
              }
              else {
                element.style.display = 'block';
              }
            }
          });
        }
      }
    });

    const newData = this.state.boolean;
    newData[`que-${uid}`] = info; 
    this.setState({
      boolean: newData,
      requireFields: false,
    });
  };
  render() {

    
    return (
      
      <div className="survey-main-container">
        <div className="row">
          <div className="col-md-12 mb-3">
            <h2 className="pagettl mb-2">{this.props.survey.surveyName}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="d-flex justify-content-end"> 
            <div className="surveybtncontainer">
            {this.props.isPublicSurvey ? (
                ""
              ) : (
                <Button
                  className="generalbtn pl-3 pr-3 pt-2 pb-2 skip-btn mr-4"
                  type="button"
                  onClick={this.handleSkip}
                >
                  {this.props.t("survey.skipButton")}
                </Button>
              )}

            <Button
                className="generalbtn pl-3 pr-3 pt-2 pb-2 survey-btn"
                type="button"
                onClick={this.handleStore}
                disabled={true}
              >
                {this.props.t("survey.storeButton")}
              </Button>
              </div>
              </div>
              <div className="survey-head">
              <label dangerouslySetInnerHTML={{ __html: this.props.survey.toptext }} />
              </div>
            </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            {this.props.question.map((key, i) => (
              <React.Fragment key={i}>
                {
                  {
                    "Yes/No": (
                      <SurveyBoolen
                        setUpdateRadio={this.updatehandleBoolean}
                        data={key}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                    Text: (
                      <SurveyTextArea
                        setUpdateTextArea={this.updatehandleTextArea}
                        data={key}
                        uid={i}
                        questionId={key.questionId}
                        response={this.props.response}
                        value={this.props.value}
                      />
                    ),
                    Date: (
                      <SurveyDatepicker
                        setUpdateDate={this.updatehandleDate}
                        data={key}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                    "Multiple Choice": (
                      <SurveyMCQ
                        setUpdateMCQ={this.updatehandleMCQ}
                        uid={i}
                        data={key}
                        questionId={key.questionId}
                        response={this.props.response}
                      />
                    ),
                    "Single Choice": (
                      <SurveySingleChoice
                        setUpdateValue={this.updatehandleSingleChoice}
                        uid={i}
                        data={key}
                        response={this.props.response}
                      />
                    ),
                    Amount: (
                      <SurveyNumber
                        setUpdateAmount={this.updatehandleAmount}
                        data={key}
                        number={true}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                    "Whole Number": (
                      <SurveyNumber
                        setUpdateWholeNO={this.updatehandleWhole}
                        data={key}
                        number={false}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                    SingleLineText: (
                      <SurveySingleLineText
                        setUpdateSinglelinetext={this.updatesinglelinetext}
                        data={key}
                        number={false}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                    "Rating1to6": (
                      <Survey6Stars
                        setUpdate6StarRating={this.updatehandleRating1to6}
                        data={key}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                    "Rating1to10": (
                      <Survey10Stars
                        setUpdate10StarRating={this.updatehandleRating1to10}
                        data={key}
                        uid={i}
                        response={this.props.response}
                      />
                    ),
                  }[key.responseType]
                }
              </React.Fragment>
            ))}
            {this.state.requireFields ? (
              <p style={{ color: "red" }}>
                {this.props.t("survey.submitError")}
              </p>
            ) : null}
             {this.state.storeButtonRequireFields? (
              <p style={{ color: "red" }}>
                {this.props.t("survey.storeError")}
              </p>
            ) : null}
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="survey-head">
                  <label dangerouslySetInnerHTML={{ __html: this.props.survey.bottomtext }} />
                </div>
              </div>
            </div>
            <div className="surveybtncontainer">
            {this.props.isPublicSurvey ? (
                ""
              ) : (
                <Button
                  className="generalbtn pl-3 pr-3 pt-2 pb-2 skip-btn"
                  type="button"
                  onClick={this.handleSkip}
                >
                  {this.props.t("survey.skipButton")}
                </Button>
              )}
                <Button
                className="generalbtn pl-3 pr-3 pt-2 pb-2 survey-btn"
                type="button"
                onClick={this.handleStore}
                disabled={true}
              >
                {this.props.t("survey.storeButton")}
              </Button>
              <Button
                className="generalbtn pl-3 pr-3 pt-2 pb-2 ml-3 survey-btn"
                type="button"
                onClick={this.handleSubmit}
              >
                {this.props.survey.isSigningByBankId == false ? this.props.t("survey.submitButton") : "Send for signing"}
              </Button>
            
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  savesurvey: state.user.postSurvey,
  storeSurvey:state.user.storeSurvey
})

const mapDispatchToProps = {
  postSurvey,
  storeSurvey
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(RenderSurvey)));
