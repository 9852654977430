import React, { Component } from 'react';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
import Form from 'react-bootstrap/Form';

export default class SurveySinglelinetext extends Component {
    constructor(props) {
        super(props)

        this.state = {
text:""
        }
    }

    componentDidMount() {
        const question = this.props.data.questionId
        const response = this.props.response
        const matchResponse = response?.SurveyResponses?.find(questionid => questionid.questionId === question)
        
        
        if (matchResponse) {
            this.setState({
                text: matchResponse.answer,
            })
            this.props.setUpdateSinglelinetext({"required":this.props.data.isMandatoryQuestion,"data":matchResponse.answer},this.props.uid)
        }
    }
    handlesinglelinetext = (e) => {
            this.props.setUpdateSinglelinetext({"required":this.props.data.isMandatoryQuestion,"data":e.target.value},this.props.uid)
    }

    render() {
        return (
            <Form.Group>
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                                {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                            </p>
                            <Form.Check
                                type="text"
                                id="examplesinglelinetext"
                                className="surveysinglelinetext"
                                onChange={this.handlesinglelinetext}
                                value={this.state.value}
                            />
                        </div>
                    </div>
                </div>
            </Form.Group>
        )
    }
}

